/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-viridian: #25815F;
  --color-emerald: #56B77A;
  --color-platinum: #D9DCDE;
  --color-butterscotch: #DE8C3A;
  --color-grey: #666;
  
  /* Dark mode variations */
  --color-dark-bg: #1a1a1a;
  --color-dark-surface: #242424;
  --color-dark-border: #333333;
}

.app {
  background-color: #1a1a1a;
  color: #fff;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  background-color: var(--color-dark-surface);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transition: transform 0.3s ease;
}

/* Content wrapper - allows for proper scrolling with fixed header/footer */
.sidebar-content {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 130px); /* Adjust for top toggle button and bottom bar */
  margin-top: 60px; /* Space for toggle button */
  margin-bottom: 70px; /* Space for bottom bar */
  padding: 0;
}
.sidebar h2 {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1rem;
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
}
.sidebar.collapsed {
  transform: translateX(-400px);
}

.sidebar-toggle {
  position: fixed;
  left: 20px;
  top: 20px;  /* Consistent top margin */
  background-color: rgba(51, 51, 51, 0.8);
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1001;
  backdrop-filter: blur(5px);
  color: var(--color-platinum);
  font-size: 18px;
  transition: left 0.3s ease;
  height: 36px;  /* Match search input height */
}
.sidebar-toggle:hover {
  background-color: var(--color-emerald);
}
.sidebar:not(.collapsed) + .sidebar-toggle {
  left: 360px;
}

.search-bar {
  padding: 10px 20px;
  background-color: var(--color-dark-surface);
  margin-left: 70px;  /* Align with the toggle button width + padding */
  margin-right: 20px;
  margin-top: 18px;  /* Match toggle button's top margin */
  height: 40px;  /* Adjust height to match toggle button */
  display: flex;
  align-items: center;
}

.search-bar-full {
  padding: 10px;
  background-color: var(--color-dark-surface);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.browse-button {
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.browse-button:hover {
  background-color: var(--color-butterscotch);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* Episode modal styling */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--color-dark-surface);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90vw;
}
.match-search {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--color-dark-border);
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  font-size: 14px;
  height: 36px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 900px;
  background-color: #1a1a1a;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
}

.movie-details {
  position: relative;
  min-height: 500px;
}

.movie-content {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 30px;
  padding: 30px;
  padding-top: 180px;
}

.movie-poster {
  flex-shrink: 0;
  margin-top: -100px;
}

.movie-poster img {
  width: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.movie-info {
  flex: 1;
}

.movie-info h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--color-platinum);
}

.movie-meta {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
}

.year {
  font-size: 1.1rem;
  color: #9ca3af;
}

.badge {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #e5e7eb;
}

.overview {
  font-size: 1rem;
  line-height: 1.7;
  color: #9ca3af;
  margin-bottom: 30px;
}

.action-buttons {
  display: flex;
  gap: 15px;
}

.watch-now-btn {
  padding: 12px 24px;
  background-color: #25815F;
  color: var(--color-platinum);
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.watch-now-btn:hover {
  background-color: #1e6b4e;
}

.close-btn {
  padding: 12px 24px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-platinum);
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.close-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .movie-content {
    flex-direction: column;
    padding-top: 30px;
  }
  
  .movie-poster {
    margin-top: 0;
    align-self: center;
  }
  
  .movie-info h1 {
    font-size: 1.8rem;
  }
}
.episode-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 8px;
  padding: 16px;
}

.episode-button {
  background-color: var(--color-dark-surface);
  border: 1px solid var(--color-dark-border);
  color: var(--color-platinum);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.episode-button:hover {
  background-color: var(--color-emerald);
}

.season-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  background-color: var(--color-dark-surface);
  border: 1px solid var(--color-dark-border);
  color: var(--color-platinum);
  border-radius: 4px;
}

.episode-selector {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}
.episode-selector select:focus {
  outline: none;
  border-color: var(--color-emerald);
  box-shadow: 0 0 0 1px var(--color-emerald);
}

.selectors-container select {
  background-color: rgba(0, 0, 0, 0.3);
  color: var(--color-platinum);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 1em;
  padding-right: 2.5em;
}

.selectors-container select:focus {
  outline: none;
  border-color: #25815F;
}

.selectors-container select:hover {
  border-color: rgba(255, 255, 255, 0.2);
}

/* Ensure proper spacing between elements */
.movie-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Make sure the content scrolls properly if it gets too tall */
.movie-content {
  max-height: 80vh;
  overflow-y: auto;
}

.match-item {
  padding: 14px 20px;
  color: var(--color-platinum);
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.match-search:focus {
  outline: none;
  border-color: var(--color-viridian);
}

/* Updated match header styling */
.match-header {
  color: #666;
  font-size: 14px;
  padding: 20px 20px 10px 20px;
  letter-spacing: 0.5px;
}

.match-header h2 {
  color: var(--color-grey);  /* Green text */
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Updated live badge styling */
.live-badge {
  background-color: var(--color-butterscotch);
  color: var(--color-platinum);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: 8px;
  display: inline-block;
}

/* Match item container styling */
.matches-container {
  border: none;
  margin: 0;
}
.matches-list {
  max-height: none;
  overflow-y: auto;
  background-color: var(--color-dark-surface);
}

/* Match item hover effect */
.match-item:hover {
  background-color: rgba(46, 55, 49, 0.8);
  transition: background-color 0.2s ease;
}

/* URL input and controls */
.url-input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 12px;
}

.url-input-group input {
  padding: 8px;
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  border-radius: 4px;
  width: 100%;
}

.url-input-group input:focus {
  outline: none;
  border-color: var(--color-emerald);  /* Optional: change border color on focus */
  box-shadow: 0 0 0 1px var(--color-viridian);  /* Optional: add subtle glow effect */
}
.url-input-group button:first-child {
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.url-input-group button:first-child:hover {
  background-color: var(--color-butterscotch);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}
.button-group {
  display: flex;
  gap: 10px;
}

.button-group button,
.custom-file-upload {
  background-color: var(--color-viridian);
  color: var(--color-platinum);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-group button:hover,
.custom-file-upload:hover {
  background-color: var(--color-emerald);
}

/* Volume controls */
.volume-controls {
  background-color: rgba(51, 51, 51, 0.8);
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.volume-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.volume-selector select {
  background-color: #333;
  color: var(--color-platinum);
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  flex-grow: 1;
}

.volume-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #4a5568;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.volume-slider:hover {
  opacity: 1;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #3b82f6;
  cursor: pointer;
}

.volume-slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #3b82f6;
  cursor: pointer;
}

/* URL list */
.url-list {
  margin: 20px 10px;  /* Add margin to align with toggle button */
  border: 1px solid var(--color-dark-border);  /* Add border */
  border-radius: 8px;  /* Round the corners */
  overflow: hidden;  /* Keep child elements within border radius */
  background-color: var(--color-dark-surface);
}

.url-item {
  display: flex;
  align-items: center;
  background-color: var(--color-dark-surface);
  transition: background-color 0.2s;
  height: 40px;
  padding-right: 8px;
}

.url-item:hover {
  background-color: rgba(55, 81, 62, 0.8);
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: rgba(31, 41, 55, 0.8);
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: var(--color-viridian);
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: var(--color-emerald);
}
.url-item.dragging {
  opacity: 0.5;
}

.url-item-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.window-number {
  margin: 0 8px 0 8px;
}

.url-text {
  flex-grow: 1;
  word-break: break-all;
  font-size: 10px;
  line-height: 1.2;
  margin: 0;
}

.delete-button {
  background-color: rgba(220, 53, 69, 0.8);
  color: var(--color-platinum);
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: rgba(200, 35, 51, 0.8);
}

/* Layout controls */
.layout-controls {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.layout-toggle {
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.layout-toggle:hover {
  background-color: var(--color-emerald);
}

.layout-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-dark-surface);
  border-radius: 4px;
  padding: 8px;
  margin-top: 4px;
  display: none;
  width: max-content;
}

.layout-menu.visible {
  display: block;
}

.layout-button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 12px;
  margin: 2px 0;
  background: none;
  border: none;
  color: var(--color-platinum);
  cursor: pointer;
  border-radius: 2px;
  font-size: 14px;
  min-width: 80px;
}

.layout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Update the URL item styling */
.url-controls {
  display: flex;
  gap: 4px;
}

.move-button {
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.move-button:hover:not(:disabled) {
  background-color: var(--color-emerald);
}

.move-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cycle-button {
  background-color: var(--color-viridian);
  color: var(--color-platinum);
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
}

.cycle-button:hover {
  background-color: var(--color-emerald);
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
.url-text.refreshing {
  opacity: 0.7;
}

.cycle-button.refreshing {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cycle-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
/* Add subtle indication when refreshing */
.cycle-button:active {
  transform: scale(0.95);
  transition: transform 0.1s ease;
}

/* Update video container to allow controlled interaction */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  pointer-events: auto; /* Change to auto to allow interaction */
}

.video-container .video-number {
  pointer-events: auto; /* Allow interaction with the number */
}

/* Ensure iframes can't be interacted with */
.video-container iframe {
  pointer-events: auto; /* Change to auto to allow interaction */
}

/* Video grid layouts */
.video-grid {
  width: 100vw;
  height: 100vh;
  display: grid;
  gap: 2px;
  background-color: #1a1a1a;
  padding: 2px;
}

/* Single layout */
.video-grid.single {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

/* Dual layout */
.video-grid.dual {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

/* Triple layout - 1 large left, 2 stacked right */
.video-grid.triple {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "main right1"
    "main right2";
}

.video-grid.triple .video-container:nth-child(1) {
  grid-area: main;
}

.video-grid.triple .video-container:nth-child(2) {
  grid-area: right1;
}

.video-grid.triple .video-container:nth-child(3) {
  grid-area: right2;
}

/* Quad layout */
.video-grid.quad {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

/* 2 + 4 layout */
.video-grid.two-plus-four {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "left1 right1"
    "left1 right2"
    "left2 right3"
    "left2 right4";
}

.video-grid.two-plus-four .video-container:nth-child(1) {
  grid-area: left1;
}

.video-grid.two-plus-four .video-container:nth-child(2) {
  grid-area: left2;
}

.video-grid.two-plus-four .video-container:nth-child(3) {
  grid-area: right1;
}

.video-grid.two-plus-four .video-container:nth-child(4) {
  grid-area: right2;
}

.video-grid.two-plus-four .video-container:nth-child(5) {
  grid-area: right3;
}

.video-grid.two-plus-four .video-container:nth-child(6) {
  grid-area: right4;
}

/* 3x3 Grid layout */
.video-grid.grid-3x3 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

/* 5x2 Grid layout */
.video-grid.grid-5x2 {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

/* Infinite grid layout */
.video-grid.grid-infinite {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: minmax(240px, 1fr);
  grid-auto-flow: dense;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

/* Make sure grid cells maintain aspect ratio */
.video-grid.grid-infinite .video-container {
  aspect-ratio: 16 / 9;
}

/* Video container styling */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.video-number {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--color-platinum);
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 100;
  font-weight: bold;
}

.video-muted-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--color-platinum);
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 100;
}


.video-container iframe {
  position: relative;
}

body {
  overflow: hidden; /* Prevents scrolling to hidden popups */
}

/* Override any potential popup styles */
.video-container *[class*="popup"],
.video-container *[id*="popup"],
.video-container *[class*="modal"],
.video-container *[id*="modal"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.sidebar-bottom-bar {
  position: fixed;  /* Change from absolute to fixed */
  bottom: 0;
  left: 0;
  width: 400px;  /* Match sidebar width */
  height: 60px;
  background-color: var(--color-dark-surface);
  border-top: 1px solid var(--color-dark-border);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1010;  /* Make sure it's above other content */
  transform: translateX(0);  /* Start visible */
  transition: transform 0.3s ease;  /* Smooth transition for collapse */
}

.sidebar-bottom-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--color-platinum);
  border: none;
  border-radius: 4px;
}

.sidebar-bottom-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar.collapsed + .sidebar-bottom-bar {
  transform: translateX(-400px);
}

.sidebar-bottom-button.danger {
  color: var(--color-platinum);
  background-color: rgba(220, 53, 69, 0.2);
  padding: 0 16px;
  width: auto;
}

.sidebar-bottom-button.danger:hover {
  background-color: rgba(220, 53, 69, 0.3);
}

.help-modal {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-dark-surface);
  border: 1px solid var(--color-dark-border);
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.help-modal h3 {
  color: var(--color-platinum);
  margin-bottom: 10px;
  font-size: 16px;
}

.help-date {
  color: var(--color-butterscotch);
  font-size: 12px;
  margin-bottom: 15px;
}

.help-modal ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.help-modal li {
  margin-bottom: 10px;
  color: #9ca3af;
  font-size: 14px;
}

.help-modal .shortcut {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 8px;
  font-family: monospace;
}

.browser-tips {
  border-top: 1px solid var(--color-dark-border);
  padding-top: 15px;
}

.browser-tips p {
  color: #9ca3af;
  font-size: 14px;
  margin-bottom: 12px;
}

.browser-links {
  display: flex;
  gap: 12px;
}

.browser-links a {
  color: var(--color-viridian);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

.browser-links a:hover {
  color: var(--color-emerald);
  text-decoration: underline;
}

.matches-section {
  margin: 0;
}

/* Featured matches section can remain normal */
.matches-section:first-child .matches-list {
  max-height: none;
}

.section-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  background-color: var(--color-dark-surface);
  transition: background-color 0.2s;
}


.section-header .match-header {
  padding: 0;  /* Reset padding since it's now on the parent */
  margin: 0;
  flex-grow: 1;
}
/* All Live Events section should be scrollable */

/* Style the scrollbar for the matches list */
.matches-list::-webkit-scrollbar {
  width: 6px;
}

.matches-list::-webkit-scrollbar-track {
  background: rgba(31, 55, 41, 0.354);
}

.matches-list::-webkit-scrollbar-thumb {
  background: var(--color-dark-surface);
}

.matches-list::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-surface-lighter);
}

.sidebar-content::-webkit-scrollbar,
.matches-list::-webkit-scrollbar {
  width: 6px;
}

.sidebar-content::-webkit-scrollbar-track,
.matches-list::-webkit-scrollbar-track {
  background: rgba(31, 55, 41, 0.354);
}

.sidebar-content::-webkit-scrollbar-thumb,
.matches-list::-webkit-scrollbar-thumb {
  background: var(--color-dark-surface);
  border-radius: 3px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover,
.matches-list::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-surface-lighter);
}

.video-container.active iframe {
  pointer-events: auto;
}

.video-container:not(.active) iframe {
  pointer-events: none;
}

/* Loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-platinum);
  z-index: 20;
}

.loading-spinner {
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Error overlay */
.error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--color-platinum);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  z-index: 20;
}


.video-container iframe {
  pointer-events: none;
}

.sidebar-content {
  padding: 0; /* Remove default padding */
}

/* Main navigation buttons */
.browse-button {
  background-color: var(--color-dark-surface);
  color: var(--color-platinum);
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  transition: all 0.2s ease;
  border-radius: 8px;
  border-color: transparent;
}

.browse-button:hover {
  background-color: var(--color-butterscotch);
}

/* Section headers */
.match-header {
  color: #666;
  font-size: 14px;
  padding: 20px 20px 10px 20px;
  letter-spacing: 0.5px;
}

/* Featured matches section */
.matches-list {
  margin: 0;
  padding: 0;
}

.match-item {
  padding: 8px 20px;
  color: var(--color-platinum);
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  transition: background-color 0.2s;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.match-item:hover {
  background-color: rgba(46, 55, 49, 0.8);
}

/* Remove spacing between sections */
.matches-container,

.matches-section {
  border: none;
  margin: 0;
  padding: 0;
}

/* Bottom bar adjustments */
.sidebar-bottom-bar {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-dark-surface);
}

/* URL input modal */
.url-input-modal {
  position: fixed;
  bottom: 70px;
  left: 0;
  width: 400px;
  background-color: var(--color-dark-surface);
  border-top: 1px solid var(--color-dark-border);
  padding: 16px;
  z-index: 1011;
}

.url-input-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.url-input {
  width: 100%;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-dark-border);
  border-radius: 4px;
  color: var(--color-platinum);
}

.url-input-buttons {
  display: flex;
  gap: 8px;
}

.url-input-add,
.url-input-cancel {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.url-input-add {
  background-color: var(--color-viridian);
  color: var(--color-platinum);
}

.url-input-cancel {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-platinum);
}